.c-tabs-focus {
    @include media-query(md) {
        position: fixed;
        top: 0;
    }
}



.c-tabs {

    @include media-query(md) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
}
.c-tabs__anchor {
    color: $color-grey--dark;
    padding: .5em $spacing;
    display: block;
    border-top: 2px solid $color-green--dark;
    border-bottom: 2px solid mix($color-grey--dark, $color-beige, 20%);
    margin-left: ($spacing * -1);
    margin-right: ($spacing * -1);
    margin-bottom: -2px;

    @include media-query(sm) {
        margin-left: ($spacing * -3);
        margin-right: ($spacing * -3);
        padding-left: ($spacing * 3);
    }
    @include media-query(md) {
        border: 2px solid mix($color-grey--dark, $color-beige, 20%);
        padding: .5em ($spacing / 2);
        border-bottom: 2px solid $color-green--dark;
        order: -1;
        margin-left: 0.25em;
        margin-right: 0.25em;
        font-size: 0.875em;
    }

    &:hover,
    &:focus {
        border-bottom: 2px solid $color-green--dark;
    }
    &:hover {
        border-color: $color-green--dark;
        color: $color-green--dark;
    }

    &.is-collapsed {
        border-bottom: 2px solid $color-green--dark;
    }
    &.is-expanded {
        border-bottom: 2px solid $color-green--dark;
        background-color: $color-green--dark;
        position: relative;
        z-index: 1;
        color: $color-white;
    }
    &.is-chosen {
        @include media-query(md) {
            border-color: $color-green--dark;
            border-bottom: 2px solid $color-beige !important;
            background-color: transparent;
            position: relative;
            z-index: 1;
            cursor: default;
            color: currentColor;
        }
    }
}
.c-tabs__section {
    @include media-query(md) {
        border-top: 2px solid $color-green--dark;
    }
    &.is-collapsed {
        display: none !important;
    }
    &.is-expanded {
        display: block;
    }
    &.is-chosen {
        @include media-query(md) {
            display: block !important;
        }
    }
}
