.c-hero {
    position: relative;
    padding-left: ($spacing / 2);
    padding-right: ($spacing / 2);
    max-width: 1600px;
    margin: auto;
    margin-bottom: $spacing;

    @include media-query(md) {
        margin-top: -($spacing);
    }
}
.c-hero__title {
    font-size: calc(1em + 8vw);
    font-family: $font-sans;
    line-height: 1.25;
    text-transform: uppercase;

    @include media-query(md) {
        font-size: calc(2em + 8vw);
    }
    @include media-query(lg) {
        font-size: calc(4em + 6vw);
        letter-spacing: -0.025em;
    }
}
.c-hero__message {
    text-align: center !important;
    order: 99;

    @include media-query(md) {
        order: 0;
    }
    p {
        max-width: none;
    }
}
