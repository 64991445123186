.c-teaser {
    background-color: $color-beige;
    margin-bottom: $spacing;
    position: relative;
    display: flex;
    flex-flow: row wrap;

    @media all and (-ms-high-contrast:none) {
        min-height: 250px;
    }

    @include media-query(md) {
        align-items: center;
        flex-flow: column wrap;
        padding: ($spacing / 2);
        text-align: center;
    }

    &:hover {
        @include media-query(md) {
            background-color: $color-white;
            outline: ($spacing / 2) solid $color-white;
        }
    }
    &:hover a {
        border-bottom-color: $color-green--dark;
    }
}

.c-teaser--equal {
    flex: 1;

    @media all and (-ms-high-contrast:none) {
        flex: none;
    }
}
.c-teaser__image {
    margin-bottom: $spacing--large;
    vertical-align: top;
    max-width: 250px;
    min-width: 80px;
    width: 33.33334%;

    @include media-query(md) {
        width: auto;
    }
}
.c-teaser__body {
    padding-left: ($spacing);
    line-height: 1.5;
    flex: 1;


    @include media-query(md) {
        padding: 0 ($spacing);
    }
}
.c-teaser__title {
    margin-top: 0;
    margin-bottom: $spacing;
    line-height: 1.5;

    @include media-query(md) {
    }

    a {
        position: relative;
        z-index: 2;
    }
}
.c-teaser__sponsor {
    display: block;
    text-align: center;

    > img {
        width: 100%;
        margin: auto;
        max-width: 150px;
        max-height: 75px;
    }
}
.c-teaser__sponsor--big {
    > img {
        max-width: 400px;
        max-height: 120px;
    }
}
.c-teaser__summary {
    a {
        position: relative;
        z-index: 9;
    }
    > *:last-of-type {
        margin-bottom: 0;
    }
}
.c-teaser__clickarea {
    border: 0;
    cursor: pointer;
    text-decoration: none;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


.c-teaser--landscape {
    align-items: flex-start;
    flex-flow: row-reverse wrap;
    text-align: left;

    .c-teaser__image {
        margin-left: auto;
    }
}
