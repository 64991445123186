.kicker {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: normal;
    letter-spacing: 0.025em;
}


#filter {
    padding: 0.5em;
    border-bottom: 2px solid $color-grey--light;
}

//
// .push-left-transition {
//     transition: transform 0.3s ease;
// }
// .push-left-enter,
// .push-left-leave {
//     transform: translateX(-100%);
//     position: absolute;
// }
//
// .push-right-transition {
//     transition: transform 0.3s ease;
// }
// .push-right-enter,
// .push-right-leave {
//     transform: translateX(100%);
//     position: absolute;
// }
