.c-session {
    background-color: $color-beige;
    margin-bottom: $spacing--large;
    position: relative;
    @include clearfix;

    &:hover {
        @include media-query(md) {
            background-color: $color-white;
            outline: ($spacing) solid $color-white;
        }
    }
    &:hover a {
        border-bottom-color: $color-green--dark;
    }
}

.c-session__title {
    margin-top: 0;
    margin-bottom: $spacing;
    line-height: 1.5;

    @include media-query(md) {
    }

    a {
        position: relative;
        z-index: 2;
    }
}
.c-session__speaker {
    margin-bottom: $spacing;
    vertical-align: top;
    float: left;
    margin-right: ($spacing);
}
.c-session__image {
    padding-right: ($spacing / 2);
    width: 60px;
}
.c-session__summary {
    a {
        position: relative;
        z-index: 9;
    }
    > *:last-of-type {
        margin-bottom: 0;
    }
}
.c-session__clickarea {
    border: 0;
    cursor: pointer;
    text-decoration: none;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
