.c-navigation {

    @include media-query(md) {
        display: flex;
        flex-direction: column-reverse;
    }

    a:hover {
        color: $color-green;
    }
}
