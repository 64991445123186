.c-card {
    background-color: red;
    margin-bottom: $spacing;
    position: relative;
    box-shadow: 2px 4px 15px rgba($color-grey--light, 0.15);

    &:hover a {
        border-bottom-color: currentColor;
    }
}
.c-card--equal {
    flex: 1;

    @media all and (-ms-high-contrast:none) {
        min-height: 250px;
    }
}
.c-card__image {
    max-width: 120px;
    margin-left: $spacing;
    margin-bottom: $spacing--large;
    float: right;
}
.c-card__title {
    margin-top: 0;
    margin-bottom: $spacing;
    line-height: 1.5;

    a {
        position: relative;
        z-index: 2;
    }
}
.c-card__summary {}
.c-card__clickarea {
    border: 0;
    cursor: pointer;
    text-decoration: none;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {
        border-color: $color-green;
    }
}

.c-card--sponsor {
    background-color: $color-white !important;
    text-align: center;
    padding: ($spacing / 4);

    img {
        width: 100%;
        margin: auto;
        max-width: 256px;
        max-height: 144px;
    }
}
.c-card--sponsor-big {
    img {
        max-width: 480px;
        max-height: 270px;
    }
}
