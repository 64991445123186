




.c-menu {
    font-family: $font-sans;
    padding-top: .125rem;
    text-align: left;

    &__button {
        float: right;
        background-color: transparent;
        border: 2px solid $color-green;
        padding: 0.5em 1em;
        color: currentColor;

        @include media-query(md) {
            display: none;
        }
        &:hover {
            border-color: $color-grey--dark;
            color: $color-green--dark !important;
        }
    }
    &__list {
        clear: both;
        list-style: none;
        padding-left: 0;
        margin: 0;


        .is-closed  & {
            overflow: hidden;
            max-height: 0;

            @include media-query(md) {
                max-height: 150px;
            }
        }
        .is-open & {
            border-top: 1px solid $color-grey--light;
            max-height: none;

            @include media-query(md) {
                display: flex;
                border: 0;
            }
        }
    }
    &__list-item {
        @include media-query(md) {
            display: inline-block;
        }
    }
    &__list-item + &__list-item {
        border-top: 1px solid $color-grey--light;

        @include media-query(md) {
            margin-left: 1.5em;
            border: 0;
        }
    }
    &__list-item--alt {
        @include media-query(md) {
            float: right;
        }
    }
    &__link {
        text-align: left;
        text-decoration: none;
        color: $color-grey--dark;
        border: 0;
        padding: 0.75em 0;
        display: block;

        @include media-query(md) {
            font-size: 1.125rem;
            padding: 0.25em 0;
        }
    }
    &__link--alt {
        @include media-query(md) {
            border: 2px solid $color-green;
            padding: 0.5em 1em;

            &:hover {
                border-color: $color-grey--dark;
                color: $color-green--dark !important;
            }
        }
    }
    &__link--lang {
        background-color: $color-green--dark;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        color: $color-white;
        text-align: center;
        text-transform: uppercase;
        line-height: 0.55;
        margin: 0.65em 0;

        @include media-query(md) {
            line-height: 2;
            font-size: 0.76em;
            margin: 0;

        }
        &:hover {
            color: $color-white !important;
            background-color: $color-grey--dark;
        }
    }
    &__link--active {
        border-bottom: 3px solid $color-green;
    }
}
