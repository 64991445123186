.c-form__options {
    position: relative;
}
.c-form__item {
    margin-bottom: 1rem;

    label {
        font-family: $font-sans;
        font-size: 0.8125em;
        color: $color-grey;
    }
    input,
    textarea {
        font-size: 1.125rem;

        &:focus {
            outline: 2px solid $color-white;
            outline-offset: -2px;
        }
    }
    input {
        border-bottom: 2px solid $color-green--dark;
        padding: .5em;

        &:focus {
            outline: 2px solid $color-green;
            outline-offset: -2px;
        }
    }
    textarea {
        padding: .25em;
        min-height: 5em;
    }
}
.c-form__item--inline {
    display: inline-block;
    margin-right: 1em;
}
.c-form__item--yummy {
    display: none;
}
.c-form__item--radio,
.c-form__item--checkbox {
     > input[type="radio"],
     > input[type="checkbox"] {
        position: absolute;
        clip: rect(0,0,0,0);
        clip: rect(0 0 0 0);
    }
}
.c-form__item--radio {
    > input[type="radio"] + label {
        padding-left: 50px;
        margin-bottom: 0;
        display: inline-block;
        cursor: pointer;
    }
    label > span {
        color: currentColor;
        margin: 0;
    }

    .svg-radio {
        margin-left: -50px;
        vertical-align: baseline;
        transform: translateY(-0.125em);
        margin-right: .5em;
        display: inline-block;
        float: left;
    }
    input[type="radio"] + label {
        .svg-radio__base {
            stroke: $color-green;
            fill: $color-white;
        }
        .svg-radio__core { fill: $color-white; }
    }
    input[type="radio"] + label:hover {
        .svg-radio__core { fill: $color-green--dark; }
    }
    input[type="radio"]:disabled + label {
        color: $color-grey--light;
        .svg-radio__base { stroke: $color-grey--light; }
        .svg-radio__core { fill: $color-white; }
    }
    input[type="radio"]:checked + label {
        .svg-radio__core { fill: $color-grey--dark; }
    }
    input[type="radio"]:disabled:checked + label {
        .svg-radio__base { stroke: $color-grey--light !important; }
        .svg-radio__core { fill: $color-grey--light !important; }
    }
}

.c-form__button {
    font-family: $font-sans;
    font-weight: normal;
}
