.c-program {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: ($spacing * 2);

    &__item {
        padding-top: 1.75rem;
        padding-bottom: 1.5rem;

        @include media-query(sm) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__item + &__item {
        border-top: 2px solid $color-green--dark;
    }
    &__time {
        margin: 0 ($spacing / 2) ($spacing / 2) 0;
        white-space: nowrap;
        line-height: 1.6;
        flex: 0 1 8rem;
    }
    &__title {
        margin: -0.175em 0 0.5em 0;
    }
    &__content {
        flex: 1 1 24rem;
        min-height: 1em;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}
