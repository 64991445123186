@import url("http://fast.fonts.com/t/1.css?apiType=css&projectid=a4574e90-bf73-4612-8a19-20082fc4b6e8");

@font-face {
    font-family:"Avant Garde";
    font-weight: normal;
    src:url("../fonts/85564178-fd88-4387-9226-0e632723ca24.eot?iefix") format("eot");
}
@font-face {
    font-family:"Avant Garde";
    src: url("../fonts/85564178-fd88-4387-9226-0e632723ca24.eot?iefix");
    src: url("../fonts/e9a79553-7093-47c7-8d9c-70dc3619e94b.woff") format("woff"),
    	 url("../fonts/7265b8d0-a67c-4a8b-84aa-273d1759270a.ttf") format("truetype"),
    	 url("../fonts/75d377f2-28ee-43f8-b231-44f8eba218c4.svg#75d377f2-28ee-43f8-b231-44f8eba218c4") format("svg");
}
