.c-circle {
    display: inline-block;
    max-width: 325px;

    > img {
        overflow: hidden;
        border-radius: 50%;
        max-width: 100%;
    }
    > figcaption {
        margin-top: .5em;
    }
}
