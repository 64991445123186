.c-ticket > label {
    position: relative;
}
.c-ticket > input[type="radio"] +label .svg-radio {
    position: absolute;
    bottom: 1.25em;
    left: 1.5em;
}


.c-ticket > input[type="radio"] + label {
    box-shadow: 0 1px 2px rgba($color-grey--dark, 0.5);
    cursor: pointer;
    padding: 1.5rem;
    background-color: $color-grey--dark;
    border: 3px solid $color-grey--light;
    outline: 0px solid $color-grey;
    outline-offset: -3px;
    display: block;
    padding-bottom: 4em;
    margin-bottom: 1em;

    @include media-query(md) {
        padding-bottom: 6em;
    }

    .svg-radio { margin-left: 0; }
    .svg-radio__base,
    .svg-radio__core { fill: $color-grey; }

}
.c-ticket > input[type="radio"] + label:hover {
    transition: outline 100ms ease;
    outline: 3px solid $color-white;
    outline-offset: -3px;

    .svg-radio__core { fill: $color-grey--dark; }
}
.c-ticket > input[type="radio"]:checked + label:hover {
    border-color: $color-green;
    outline: 0;

    .svg-radio__core { fill: $color-grey--dark; }
}
.c-ticket > input[type="radio"] + label:active {
    transition: transform 100ms ease, box-shadow 100ms linear;
//     transform: translateY(2px);
//     box-shadow: 0 0 0 $color-grey;
}
