.c-greenlife {
    background: url('/../assets/gfx/bg.png') no-repeat 50% 0;
    background-size: 100%;
    margin-top: $spacing;
    padding-bottom: 45%;
    max-width: $site-width;
    margin-left: auto;
    margin-right: auto;

    @include media-query(md) {
        padding-bottom: 30rem;
    }
}
