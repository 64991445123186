$site-width: 72em;


// Colors
$color-white: #fff;
$color-beige: #F7F8F3;
$color-grey: #323232;
$color-grey--light: #999999;
$color-grey--dark: #1D1D1D;
$color-green: #a8cd25;
$color-green--light: #CEE7A2;
$color-green--dark: #619210;


$text-color: $color-grey;
$background-color: $color-grey;



// Fonts
$font-sans: "Avant Garde", "Helvetica Neue", Arial, sans-serif;
$font-serif: "Zocalo Text", Georgia, serif;


// $font-serif: "Georgia", "Times", serif;




// Text
$line-height: 30px;



// Widths Columns
$widths-columns: (
    1,
    2,
    3,
    4,
);


$widths-columns-responsive: (
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
);





// Breakpoints
$breakpoints: (
    "sm"       "screen and (min-width: 40em)",
    "md"       "screen and (min-width: 60em)",
    "lg"       "screen and (min-width: 75em)",
    "xl"       "screen and (min-width: 85em)",
    "xl-down"       "screen and (max-width: 85em)",
    "lg-down"       "screen and (max-width: 75em)",
    "md-down"       "screen and (max-width: 60em)",
    "sm-down"       "screen and (max-width: 40em)"
);
