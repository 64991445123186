.o-box {
    background-color: $color-beige;
    border-radius: 1px;
}
.o-box--foliage {
    padding: ($spacing);
    box-shadow: 0 5px 20px rgba($color-grey, 0.25);

    @include media-query(sm) {
        padding: ($spacing) ($spacing * 3)  ($spacing * 2);

    }
    @include media-query(md) {
        box-shadow: 0 10px 40px rgba($color-grey, 0.5);
        padding: ($spacing) ($spacing * 4)  ($spacing * 4);
    }
    @include media-query(lg) {
        padding: ($spacing) ($spacing * 6) ($spacing * 4);
    }
}
