.c-button {
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    font: $font-sans;
    font-family: $font-sans;
    text-align: center;
    cursor: pointer;
    overflow: visible;
    padding: .6em 1.6em;
    margin:  0;
    background-color: $color-beige;
    color: $color-grey--dark;
    border: 2px solid $color-green;

    &:hover {
        border-color: $color-grey--dark;
        color: $color-green--dark;
    }
}
.c-button--big {
    padding: .75em 1.5em;
    font-size: 1.25em;
    border-width: 3px;
}
.c-button--full {
    display: block;
}
