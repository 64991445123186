.c-logo {
    float: left;

    @include media-query(md) {
        margin-bottom: $spacing;
        padding-top: 1em;
        align-self: center;
        text-align: center;
    }

    > a, span {
        font-family: $font-sans;
        text-decoration: none;
        color: inherit;
        border: 0;

        position: relative;
        font-size: 1.25em;
        line-height: 1.15;
        margin: 0;

        @include media-query(md) {
            font-size: 2.75em;
            line-height: 1.5;
        }
    }
    > p {
        margin: 0;

        @include media-query(md) {
            font-size: 1.5rem;
        }
    }
}
.c-logo__tagline {
    max-width: 55%;
    @include media-query(md) {
        max-width: none;
    }
}
.c-logo--header {
    @include media-query(md) {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }
}
.c-logo--content {
    float: none;

    @include media-query(md-down) {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }
}
