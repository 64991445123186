.c-table {
    width: 100%;
    text-align: left;
    table-layout: auto;

    th, td {
        padding: 0.5em;
        font-weight: normal;
        font-size: 0.875em;
    }
    thead th {
        font-family: $font-sans;
        font-size: 0.875em;
        padding-bottom: 0.5em;
        background-color: $color-grey;
        color: $color-white;
        line-height: 1;
    }
    tbody tr > * {
        line-height: 2;
    }
    tbody tr:nth-child(odd) > * {
        background: $color-white;
    }
    tbody td {
        @include media-query(xl) {
            border-left: 2px solid $color-white;
            padding-left: ($spacing / 2);
            padding-right: ($spacing);
        }
    }
    tbody tr:nth-child(odd) > td {
        @include media-query(xl) {
            border-left: 2px solid rgba($color-beige, 0.5);
        }
    }
}

@include media-query(xl-down) {
    .c-table thead {
        display: none;
    }
    .c-table tr {
        display: block;
        padding: ($spacing / 2);
        border-top: 1px solid rgba($color-grey--light, 0.25);
        position: relative;

        &:nth-child(odd) {
            background: $color-white;
        }
    }
    .c-table tr > * {
        flex: 0;
        padding: 0;
    }
    .c-table th {
        display: block;
        font-family: $font-sans;
        flex: 1 0 100%;
        text-transform: capitalize;
    }
    .c-table td {
        padding-right: ($spacing / 2);
        display: inline-block;
    }
    .c-table .label:before {
        content: attr(data-th)': ';
        color: $color-grey--light;
    }
    .c-table .empty {
        display: none;
    }
}
@include media-query(sm-down) {
    .c-table td {
        display: block;
    }

}
