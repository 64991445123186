.c-collaborators {

    h3 {
        margin-bottom: 1em;
    }
    ul {}
    li {
        margin-right: ($spacing * 2);
    }
    a {
        border: 0;
    }
}
.c-collaborators__logo {
    vertical-align: top;
}
