.c-rich-content {
    // figure {
    //     margin-left: ($spacing * -1);
    //     margin-right: ($spacing * -1);
    //     display: block;
    //
    //     @include media-query(sm) {
    //         margin-left: ($spacing * -3);
    //         margin-right: ($spacing * -3);
    //     }
    //     @include media-query(md) {
    //         margin-left: ($spacing * -4);
    //         margin-right: ($spacing * -4);
    //     }
    //     @include media-query(lg) {
    //         margin-left: ($spacing * -6);
    //         margin-right: ($spacing * -6);
    //     }
    // }
    // figcaption {
    //     padding-top: 0.5em;
    //     padding-left: ($spacing);
    //     padding-right: ($spacing);
    //
    //     @include media-query(sm) {
    //         padding-left: ($spacing * 3);
    //         padding-right: ($spacing * 3);
    //     }
    //     @include media-query(md) {
    //         padding-left: ($spacing * 4);
    //         padding-right: ($spacing * 4);
    //     }
    //     @include media-query(lg) {
    //         padding-left: ($spacing * 6);
    //         padding-right: ($spacing * 6);
    //     }
    // }
    > div
    > figure,
    > .c-video {
        margin-bottom: 1.5em;
    }
}
