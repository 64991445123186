.o-wrapper--foliage {
    background: url('/../assets/gfx/bg.png') repeat-y 50% 0;
    background-size: 100%;
    padding: ($spacing);

    @include media-query(sm) {
        padding: ($spacing * 3);
    }
    @include media-query(md) {
        padding: 15%;
    }
    @include media-query(lg) {
        padding: ($spacing * 7);
    }
}
