.c-cta {
    position: relative;
    margin-bottom: ($spacing * 2);

    @include media-query(md) {
        background: url('../../../../assets/img/call-to-action-footer.jpg') no-repeat 50% 50%;
        background-size: contain;
    }
    @include media-query(lg) {
        margin-left: ($spacing * -3);
        margin-right: ($spacing * -3);
    }
}
.c-cta__image {
    @include media-query(md) {
        visibility: hidden;
    }
}
.c-cta__text {
    text-align: center;

    @include media-query(md) {
        width: 35%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
