.c-list-line,
.c-list-line__item {
    border-top: 1px solid $color-grey--light;
}
.c-list-line {
    border-top: 2px solid $color-grey--light;
}
.c-list-line__item {
    padding: .25rem 0;
}
