html {
    background-color: $color-beige;
    height: 100%;
}
body {
    background-color: $color-beige;
    font-family: $font-serif;
    min-height: 100%;

    -webkit-font-feature-settings: "lnum";
       -moz-font-feature-settings: "lnum";
            font-feature-settings: "lnum";
    font-variant-numeric: lining-nums;

    @include media-query(md) {
        font-size: 112.5%;
    }
}

hr {
    border: 0;
    margin: 0;
    height: 2px;
    background-color: $color-grey--dark;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-sans;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.125;
    font-weight: normal;

    -moz-osx-font-smoothing: greyscale;
     -webkit-font-smoothing: antialiased;
}
h1, .h1 {
    font-size: 2em;
    margin-top: .5em;

    @include media-query(md) {
        margin-top: 1em;
        font-size: 2.5em;
    }
}
h2, .h2 {
    font-size: 1.35em;
}
h3, .h3 {
    font-size: 1.25em;
    line-height: 1.35;
}
h4, .h4 {
    font-family: $font-serif;
    font-style: italic;
    font-size: .875em;
}
h5, .h5 {}
h6, .h6 {}

p {
    margin-top: 0;
    margin-bottom: 1.5em;
}
p > strong {
    font-size: 0.8125em;
    text-transform: uppercase;
}
p + figure {
    margin-top: 1.5em;
}
em {}
strong {
    font-family: $font-sans;
    font-weight: normal;
}
ul,
ol {
    padding-left: 1.2rem;
    margin-left: 0;
}
ul {
    list-style: square;
}



img {
    max-width: 100%;
    width: auto;
    height: auto;
}
figcaption {
    padding-top: 0.5em;
}
blockquote {
    border-left: 4px solid $color-green;
    padding-left: ($spacing);
    margin-bottom: 2.5em;
}
blockquote > p {
    font-family: $font-serif;
    font-style: italic;
    font-size: 1.3125em;

    &:last-of-type {
        margin-bottom: 0;
    }
}
blockquote > footer {
    font-family: $font-sans;
    font-size: 0.75em;
    text-transform: uppercase;
}



a {
    text-decoration: none;
    color: $color-white;
}


h3 > a,
li > a,
p > a {
    text-decoration: none;
    color: $color-grey;
    border-bottom: 0.125em solid $color-green;

    &:hover,
    &:focus {
        border-bottom-color: currentColor;
        // color: $color_white;
    }
}
h3 > a {
    line-height: 1.5;
}
