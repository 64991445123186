

.u-tc { text-align: center; }
.u-tl { text-align: left; }
.u-tr { text-align: right; }
.u-uc { text-transform: uppercase; }

.u-lede {
    font-size: 1.25em;
    line-height: 1.6;
    font-style: italic;

    @include media-query(md) {
        font-size: 1.3125em;
    }
}

.u-yell { font-size: 6em; }



.u-left { float: left; }
.u-right { float: right; }


.u-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
.u-hide\@md {
    @include media-query(md) {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }
}
.u-show {
    border: 0 !important;
    clip: auto !important;
    height: inherit !important;
    margin: inherit !important;
    overflow: inherit !important;
    padding: inherit !important;
    position: static !important;
    width: inherit !important;
}
.u-show\@md {
    @include media-query(md) {
        border: 0 !important;
        clip: auto !important;
        height: inherit !important;
        margin: inherit !important;
        overflow: inherit !important;
        padding: inherit !important;
        position: static !important;
        width: inherit !important;
    }
}





.u-sr-only {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
