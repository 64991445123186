
// Labels
label {
  margin-bottom: $spacing;
}

// Inputs and textareas
input,
textarea {
    background-color: $color-white;
    padding: $spacing--tiny;
    border: 0;

    &:focus {}
    &:disabled {
        border: 1px solid $color-grey;
    }
}
input {
    width: 100%;

    &[size],
    &[type="radio"],
    &[type="checkbox"] {
        width: auto;
    }
}
textarea {
    width: 100%;
}

// Checkboxes and radiobuttons
input[type="checkbox"]:first-child,
input[type="radio"]:first-child {
  display: inline-block;
  margin-right: $spacing--small;
}

// Buttons
button {
  border: none;
  font-weight: normal;
  font-family: $font-sans;
  background: $color-green;
  color: $color-grey--dark;
  padding: $spacing--small $spacing;

  &:focus,
  &:hover {}
  &:active {}

  &:disabled {
    color: $color-green;
    background: $color-grey--dark;
  }
}

// Select fields
// See blocks/_select.scss
select {
  display: block;
}

// Fieldsets and legends
fieldset {
  border: none;
  padding: 0;
  margin-bottom: $spacing--small;

  legend {
    font-size: inherit;
  }
  ul {
      margin-left: 0;
      padding-left: 0;
      list-style: none;
  }
}
