.o-layout {
    letter-spacing: -1em;

    > * {
        letter-spacing: normal;
    }
}

.o-layout--flex {
    display: flex;
    flex-wrap: wrap;
    letter-spacing: normal;

    > .o-layout__item {
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }
    > .o-layout__item--last {
        order: 99;
    }
}
.o-layout--flex-between {
    display: flex;
    flex-wrap: wrap;
    letter-spacing: normal;
    justify-content: space-between;

    .o-layout__item {
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
    }
}
.o-layout--wide {
    margin-left: ($spacing * -3);
    margin-right: ($spacing * -3);
    padding-top: ($spacing);

    > .o-layout__item {
        padding-left: ($spacing * 3);
        padding-right: ($spacing * 3);
        margin-bottom: ($spacing);
    }
}


.o-layout--flex.o-layout--right {
    justify-content: flex-end;
}

.o-layout__item--left {
    float: left;
}
.o-layout__item--right {
    float: right;
}
.o-layout__item--alt {
    @include media-query(lg) {
        margin-left: 1px;
        margin-right: 1px;
    }
}
