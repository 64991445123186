.c-login {
    background-color: $color-white;
    box-shadow: 0 5px 15px mix($color-grey, $color-beige, 5%);
    width: 24rem;
    padding: ($spacing * 2);
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div {
        margin-bottom: $spacing;
    }
    label {
        text-transform: uppercase;
        font-family: $font-sans;
        font-size: 0.75em;
    }
    input {
        background-color: $color-beige;
        border-bottom: 2px solid $color-green;
    }
    button {
        width: 100%;
        box-shadow: 0 2px 0 $color-green--dark;
    }
    span {
        display: inline-block;
    }
    button:active {
        transform: translateY(2px);
        background-color: $color-green--dark;
        box-shadow: none;
        color: $color-white;
    }
    button:focus {
        outline: 0;
    }
    button:focus > span {
        animation: pulse 1s linear infinite;
    }
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
