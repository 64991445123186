.c-video {
    position: relative;

    &--16_9 {
        padding-top: 56.25%;
    }
    &--4_3 {
        padding-top: 75%;
    }

    iframe, object {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
    }
}
